import { ContentModules } from "@/utils/enums/ContentModules"

function defaultProps() {
  const { tenantId } = useHeaderStore()
  const { data: jobShop } = useNuxtData("jobShopData")
  const route = useRoute()

  // Determine pageType by checking name of the route.
  // TODO: Extract and extend as soon as other pages (e.g. search & filter) are available
  const pageType = route.name === "index" ? "homepage" : "custom"

  return {
    tenant_id: tenantId,
    jobshop_id: jobShop.value?.jobShopId,
    jobshop_name: `${jobShop.value?.jobShopTitle}`,
    page_path: window?.location.pathname,
    page_type: pageType,
    language: jobShop.value?.locale,
  }
}

/**
 * Counts elements of array arr by property name prop
 *
 * @param arr
 * @param prop
 */
function countBy(arr, prop) {
  return arr.reduce(
    (prev, curr) => ((prev[curr[prop]] = ++prev[curr[prop]] || 1), prev),
    {},
  )
}

/**
 * Counts how often each content module is present.
 * Uses the ContentModules enum as a basis for mapping the names.
 *
 * @param trackingData
 */
function countedModules(trackingData: TrackingData) {
  const counted = countBy(trackingData.contentModules.value, "component")
  const result = {}

  for (const [key, value] of Object.entries(ContentModules)) {
    const lowerKey = key.toLowerCase()
    result[`module_${lowerKey}`] = counted[value] || 0
  }

  return result
}

/**
 * Track page was loaded.
 *
 * It can be called directly on every page mounted,
 * because Amplitude sents old events, even after initialization
 *
 * - previous_pageurl: referer
 * - modules_amount: overall total amount of modules
 * - module_x: amount of module type x on the page
 *
 * @param trackingData needs to contain page and contentModules
 * @param isHomepage boolean used to set the event name
 */
export function trackPageViewAmpl(isHomepage: boolean, trackingData: TrackingData) {
  const { $amplitude } = useNuxtApp()
  const eventName = isHomepage ? "homepage_opened" : "custompage_opened"

  $amplitude.track(eventName, {
    previous_pageurl: document.referrer,
    modules_amount: trackingData.contentModules.value?.length,
    ...countedModules(trackingData),
    ...defaultProps(),
  })
}

/**
 * Track event to GTM dataLayer .
 * Should be called after CCM19 consent given, so events aren't lost.
 */
export function trackPageViewGTM(isHomepage: boolean, trackingData: TrackingData) {
  const gtm = useGtm()
  const { data: jobShop } = useNuxtData("jobShopData")

  const pagePath = isHomepage ? "/landingpage" : `/custom-pages/${trackingData.page.value?.slug}`

  const title = trackingData.page.value?.["general-and-seo"].config.general.title
  const path = `virtual/${jobShop.value?.jobShopCompanyVanity}${pagePath}`

  window.dataLayer?.push({
    companyId: jobShop.value?.jobShopId,
    matomoSiteId:
    jobShop.value?.externalScriptSettings?.matomoSiteId,
  })

  if (gtm?.enabled()) {
    gtm.trackView(title, path, {
      path,
      title,
    })
  }
}

/**
 * Track page view event with matomo.
 */
export function trackPageViewMatomo(isHomepage: boolean, trackingData: TrackingData) {
  const { data: jobShop } = useNuxtData("jobShopData")

  const pagePath = isHomepage ? "/landingpage" : `/custom-pages/${trackingData.page.value?.slug}`

  const title = trackingData.page.value?.["general-and-seo"].config.general.title
  const path = `virtual/${jobShop.value?.jobShopCompanyVanity}${pagePath}`

  const _paq = window._paq || []

  _paq.push(["setCustomUrl", `${document.location.origin}/${path}`])
  _paq.push(["setDocumentTitle", title])
  _paq.push(["trackPageView"])
}

/**
 * Track scroll depth
 *
 * percentage of page scroll
 */
export function trackScrollDepth() {
  const { $amplitude } = useNuxtApp()

  const totalHeight
    = document.documentElement.scrollHeight - window.innerHeight

  const currentScrollDepth = window.scrollY

  const percentage = ((currentScrollDepth / totalHeight) * 100).toFixed(2)

  $amplitude.track("scroll_depth", {
    percentage,
    ...defaultProps(),
  })
}

/**
 * Track clicks in top navigation on an item of different type
 *
 * - type: logo, link, language, CTA
 *
 *  name:
 * - Logo: value is "logo";
 * - Link: value is title of button;
 * - Language: value is name of language.
 * - CTA: title of button
 *
 * url: if it has one
 */
export function trackNavigationClicked(navItem: NavItem) {
  const { $amplitude } = useNuxtApp()

  // link || cta || logo
  let name = navItem.el?.label || navItem.el?.text || navItem.type
  if (navItem.type === "language")
    name = navItem.el?.details?.locale

  $amplitude.track("navigation_clicked", {
    type: navItem.type, // logo, link, language, cta
    name,
    link: navItem.el?.url,
    ...defaultProps(),
  })
}

/**
 * When a module is rendered
 *
 * - module_name - key of the module
 * - template_name - key of the template
 * - module_target - analytics setting
 * - module_id - backend given id
 * - with_CTA - 0/1 if no or yes
 * - position_on_page - zero based index
 */
export function trackModuleExists(contentModules: ContentModule[], module: ContentModule) {
  const { $amplitude } = useNuxtApp()

  const pos = contentModules.findIndex((obj) => {
    return obj.id === module.id
  })

  $amplitude.track("module_requested", {
    module_name: module.key,
    template_name: module?.config?.general?.template.component,
    module_target: module.config?.general?.analytics?.value,
    module_id: module.id,
    with_CTA:
      module.config?.general?.ctaType?.value === "Button" // Cover
      || module.config?.general?.showCtaButton // Cards, Text
      || module.config?.general?.showCta // Chapter
      || module.key === "video_module" // Video
        ? 1
        : 0,
    position_on_page: pos,
    ...defaultProps(),
  })
}

/**
 * When a module is viewed (if called IntersectWrapper)
 *
 * - module_name - key of the module
 * - template_name - key of the template
 * - module_target - analytics setting
 * - module_id - backend given id
 * - with_CTA - 0/1 if no or yes
 * - position_on_page - zero based index
 */
export function trackModuleViewed(contentModules: ContentModule[], module: ContentModule) {
  const { $amplitude } = useNuxtApp()

  const pos = contentModules.findIndex((obj) => {
    return obj.id === module.id
  })

  $amplitude.track("module_viewed", {
    module_name: module.key,
    template_name: module.config?.general?.template.component,
    module_target: module.config?.general?.analytics?.value,
    module_id: module.id,
    with_CTA:
      module.config?.general?.ctaType?.value === "Button" // Cover
      || module.config?.general?.showCtaButton // Cards, Text
      || module.config?.general?.showCta // Chapter
      || module.key === "video_module" // Video
        ? 1
        : 0,
    position_on_page: pos,
    ...defaultProps(),
  })
}

/**
 * When an interactive element inside a module is clicked
 *
 * - module_name - key of the module
 * - template_name - key of the template
 * - module_target - analytics setting
 * - module_id - backend given id
 * - with_CTA - 0/1 if no or yes
 * - position_on_page - zero based index
 * - cta_position: in case of categories/ cards which individual one was it, else dont send
 * - cta_name
 * - cta_link
 */
export function trackModuleClick(contentModules: ContentModule[], module: any, child?: any) {
  const { $amplitude } = useNuxtApp()

  let cta = module.config?.ctaButton
  let ctaPos
  if (module.modules.length) {
    // cards
    ctaPos = module.modules.findIndex(obj => obj.id === child.id)
    cta = child.config.ctaButton
  }

  if (module.config?.general.categories) {
    // categories
    ctaPos = module.config.general.categories.findIndex(
      obj => obj.id === child.id,
    )
  }

  // standalone video
  if (module.key === "module-video") {
    cta = {
      text: "video_play_button",
      link: {
        url: module.config.general.video.source.value,
        type: "video",
      },
    }
  }

  // video in a gallery
  if (module.key === "module-gallery" && child.key === "module-video") {
    cta = {
      text: "video_play_button",
      link: {
        url: child.config.general.video.source.value,
        type: "video",
      },
    }
  }

  const pos = contentModules.findIndex((obj) => {
    return obj.id === module.id
  })

  // Set link url to scrollTarget id if link is internal
  if (cta && cta.link?.type.value === "internal")
    cta.link.url = `#${cta.link.scrollTarget.value}`

  $amplitude.track("module_clicked", {
    module_name: module.key,
    template_name: module.config?.general?.template.component,
    module_target: module.config?.general?.analytics?.value,
    module_id: module.id,
    position_on_page: pos,
    cta_position: ctaPos !== -1 ? ctaPos : undefined,
    cta_name: cta?.text || child?.title, // all others || categories
    cta_link: cta?.link.url || child?.url,
    ...defaultProps(),
  })
}
